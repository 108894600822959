import { DateTime } from 'luxon';
import { getNowJst } from './core';
import { Role, User } from '../entity/users';

export function setIconUrl(url: string): void {
  localStorage.setItem('iconUrl', url);
}
export function getIconUrl(): string | undefined {
  const value = localStorage.getItem('iconUrl');
  if (value == undefined) {
    return undefined;
  } else return value;
}
function removeIconUrl(): void {
  localStorage.removeItem('iconUrl');
}

export function setSessionExpiration(expiration: DateTime): void {
  localStorage.setItem('sessionExpiration', expiration.toString());
}
export function getSessionExpiration(): DateTime | undefined {
  const value = localStorage.getItem('sessionExpiration');
  if (value == undefined) return undefined;

  try {
    const expiration: DateTime | undefined = DateTime.fromISO(value);
    return expiration;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
function removeSessionExpiration(): void {
  localStorage.removeItem('sessionExpiration');
}

export function setUserRole(role: Role): void {
  localStorage.setItem('userRole', JSON.stringify(role));
}
export function getUserRole(): Role | undefined {
  const roleJson = localStorage.getItem('userRole');
  try {
    return roleJson != null ? JSON.parse(roleJson) : undefined;
  } catch (e) {
    console.error('ユーザーロールのJSON解析中にエラー');
    return undefined;
  }
}
function removeUserRole(): void {
  localStorage.removeItem('userRole');
}

export function removeLocalSession(): void {
  /* セッションに利用しているデータをlocalStorageから削除
   */
  removeSessionExpiration();
  removeIconUrl();
  removeUserRole();
}

// サーバ側とは独立して、画面側でサインイン扱いにするかどうかの基準
export function isSignedIn(): boolean {
  const sessionExpiration = getSessionExpiration();
  const judge =
    sessionExpiration != undefined &&
    getNowJst().toMillis() < sessionExpiration.toMillis();

  return judge;
}

export function getSignedInUser(): User | undefined {
  return {
    isSignedIn: isSignedIn(),
    sessionExpiration: getSessionExpiration(),
    role: getUserRole(),
  };
}
