import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as labsComponents from 'vuetify/labs/components';
import * as directives from 'vuetify/directives';
import 'vuetify/dist/vuetify.min.css';

export default createVuetify({
  components: {
    ...components,
    // 試験的componentも使えるようにしているが、2024/3/25時点でVSparklineは使えない状態
    ...labsComponents,
  },
  directives,
  ssr: false,
});
