/* JavaScriptに無いenum使用を避け、key:value map形式のconstを使用する
 * constにしておくとfind関数への活用がしやすい
 */

// TODO enumをconst mapに変える (findとか加工しやすい)

export enum Target {
  PROD = 'production',
  STG = 'staging',
  LOCAL = 'development',
  TEST = 'testing',
}

export const computerDevice = {
  iPhone: 'iphone',
  Unknown: 'Unknown',
};
export type ComputerDevice =
  (typeof computerDevice)[keyof typeof computerDevice];

function getDefinedComputerDevice(
  deviceString: string | undefined,
): ComputerDevice {
  return deviceString === computerDevice.iPhone
    ? computerDevice.iPhone
    : computerDevice.Unknown;
}

// Local開発・自動テスト時に使うデバイス指定 (モック挙動を操作)
export const definedComputerDevice: ComputerDevice = getDefinedComputerDevice(
  process.env.VITE_APP_DEFINED_COMPUTER_DEVICE,
);

export const serviceMode = {
  Normal: 'NormalMode',
  Maintenance: 'MaintenanceMode',
  // FreeMarket: 'FreeMarket' // たとえば
} as const;
export type ServiceMode = (typeof serviceMode)[keyof typeof serviceMode];

// サービス利用不可だった時の原因
export const unavailableReason = {
  Device: 'device',
  Browser: 'browser',
  Maintenance: 'maintenance',
} as const;
export type UnavailableReason =
  (typeof unavailableReason)[keyof typeof unavailableReason];

/* Block listデバイス名
 * cf. ua-parser-js: https://github.com/faisalman/ua-parser-js
 */
export const unavailableDevice = {
  // shabelo利用に支障があると判明している
  // Mobile: 'mobile',
  Tablet: 'tablet',
  /* shabeloで利用できるとわかっていない
   * (このデバイス利用者のトラブルで他の参加者が対話を開始できないリスクを避ける)
   */
  Console: 'console',
  SmartTv: 'smarttv',
  Wearable: 'wearable',
  Embedded: 'embedded',
} as const;

/* Block list OS名
 * cf. ua-parser-js: https://github.com/faisalman/ua-parser-js
 */
export const unavailableOs = {
  // iPhoneとiPadがdevice typeを取得できないのでOS指定で除外する
  // iOS: 'iOS',
} as const;

/* Accept listブラウザ名 (主にshabelo対応ブラウザ)
 * cf. ua-parser-js: https://github.com/faisalman/ua-parser-js
 */
export const availableBrowser = {
  ChromeHeadless: 'Chrome Headless',
  ChromeWebView: 'Chrome WebView',
  Chrome: 'Chrome',
  Chromium: 'Chromium',
  Edge: 'Edge',
  Safari: 'Safari',
  MobileSafari: 'Mobile Safari',
} as const;

export enum LoadingProgress {
  DEFAULT = 0,
  LOADING = 1,
  SUCCESS = 2,
  FAILURE = 3,
}

export const DialogueRoomPhases = {
  LoadingContext: 'LoadingContext',
  FailedLoadingContext: 'FailedLoadingContext',
  SettingMeeting: 'SettingMeeting',
  FailedSettingMeeting: 'FailedSettingMeeting',
  ReadyToMeeting: 'ReadyToMeeting',
  OnMeeting: 'OnMeeting',
  EndedMeeting: 'EndedMeeting',
};

export const backgroundEffect = {
  None: 'None',
  Blur: 'Blur', // 背景ぼかし
  SingleColor: 'SingleColor', // 単色による背景除去
} as const;
export type BackgroundEffect =
  (typeof backgroundEffect)[keyof typeof backgroundEffect];

/** ##############################
 * ユーザ
 */

export const userStatus = {
  UNCONFIRMED: 'UNCONFIRMED', // Emailなどの検証が終わっていない
  ACTIVE: 'ACTIVE', // shabelo内で利用可能
  LEAVED: 'LEAVED', // shabeloから退会済み
  LOCKED: 'LOCKED', // shabeloによって利用停止済み
} as const;
export type UserStatus = (typeof userStatus)[keyof typeof userStatus];

// `認可による分岐が画面上でも必要` な権限だけ使う
export const userPermission = {
  // 集計用DBに直接SQLを実行する機能
  Execute_Select_From_Aggregation_Db: 'Execute_Select_From_Aggregation_Db',
} as const;
export type UserPermission =
  (typeof userPermission)[keyof typeof userPermission];

/** ##############################
 * スナックバーなどの通知
 */

export const notificationType = {
  INFORMATION: 'info',
  SUCCESS: 'success',
  WARNING: 'warn',
  ERROR: 'error',
} as const;
export type NotificationType =
  (typeof notificationType)[keyof typeof notificationType];
