import { DateTime } from 'luxon';
import { getCurrentInstance } from 'vue';
import * as constants from '@/util/const';

/** ##############################
 * Composition API利用
 */

export function useCurrentInstance() {
  const instance = getCurrentInstance();
  if (!instance) throw new Error('インスタンスを取得できませんでした');
  return instance;
}

/** ##############################
 * 文字列処理
 */

export function getCharacterLength(str: string) {
  /* `str.length`はコードユニットの数を返すので、文字数を数えすためにはこちらを使う
   * cf. https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/String/length
   */
  return [...str].length;
}

/** ##############################
 * 数値処理
 */

export function roundWithOptionalDigit(value: number, digit: number): number {
  /* `value` を小数点以下任意の桁数 `digit` の位置までで四捨五入して返す (標準機能に無かった)
   * `digit` の数だけ桁を整数へ繰り上げてから小数点以下全てをMath.round()で四捨五入し、改めて桁を戻す
   */
  const base = new Array(digit)
    .fill(undefined)
    .reduce<number>((previous, _current) => {
      return previous * 10;
    }, 1);
  return Math.round(value * base) / base;
}

/** ##############################
 * 日付
 */

export function shiftToJst(dateTime: DateTime) {
  // 時間をずらすのではなく、タイムゾーンを指定するだけ
  return dateTime.setZone('Asia/Tokyo');
}

export function getNowJst() {
  return shiftToJst(DateTime.utc());
}

export function getMonthWithFirstDate(dateTime: DateTime) {
  return dateTime.set({
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}

/** ##############################
 * ブラウザ操作
 */

export function changeTabName(theme: string | undefined): void {
  /* ブラウザのタブ名 (head.title)を変更
   */

  const title = `${constants.product.name}`;
  document.title = theme ? `${theme} - ${title}` : '';
}
