import { CardProperty } from '@/entity/cards';
import { AttendeeTileInfo } from '@/entity/dialogueRooms';
import { DialogueForDisplay } from '@/entity/dialogues';
import {
  getTarget,
  getGoogleOAuth2ClientId,
  getChimeMock,
  getAudioWorkletMock,
  getFaceExpressionMock,
  getGoogleOAuthMock,
} from './environment';
import { VideoFrameProcessor } from 'amazon-chime-sdk-js';

const target = getTarget();
const isChimeMock = getChimeMock();
const isAudioWorkletMock = getAudioWorkletMock();
const isFaceExpressionMock = getFaceExpressionMock();
const isGoogleOAuthMock = getGoogleOAuthMock();

export const product = {
  name: 'shabelo',
  target,
  isChimeMock,
  isAudioWorkletMock,
  isFaceExpressionMock,
  isGoogleOAuthMock,
  mainColor: {
    rgb: '#FC6270', // 252, 98, 112
  },
};

// OAuth 2.0 認証情報
export const oauth2_0 = {
  google: {
    clientId: getGoogleOAuth2ClientId(target),
  },
};

// 4人部屋の参加者数デフォルト値
export const participantLimitDefault = 3;

export const dialogueDefault: DialogueForDisplay = {
  id: '',
  theme: '',
  detail: '',
  participantLimit: 0,
  startDate: '',
  startTime: '',
  endTime: '',
  dressCode: {},
};

export const dialogueCardPropertyDefaultParams: CardProperty = {
  elementID: '0',
  width: '330px',
  height: '440px',
  imageHeight: '100px',
  titleClass: ['text-h6 font-weight-bold text-wrap'],
  textClass: ['pt-0 px-5'],
};

export const attendeeTileDefault: AttendeeTileInfo = {
  attendeeId: null,
  isLocalTile: false,
  tileId: null,
  nameplate: '-',
  isSpeaking: false,
  micVolume: 0,
  audioSignalStrength: 1,
};

export const dummyVideoProcessor: VideoFrameProcessor = {
  process: () => {
    return new Promise((resolve) => resolve([]));
  },
  destroy: () => {
    return new Promise((resolve) => resolve());
  },
};

// 少数点以下の桁数上限
export const baseDigitUnderPoint = 3;
