import {
  RouteLocationNormalized,
  NavigationGuardNext,
  NavigationGuard,
} from 'vue-router';
import { User } from '@/entity/users';
import { getUserAgent, UserAgent } from '@/util/userAgent';
import {
  serviceMode,
  ServiceMode,
  availableBrowser,
  unavailableDevice,
  unavailableReason,
  userPermission,
} from '@/util/enum';
import { getSignedInUser } from '@/util/session';

/* Clojure backendライブラリ `Pedestal`のinterceptorを意識している
 * cf. http://pedestal.io/guides/hello-world-content-types#_interceptors
 */
interface InterceptorContext {
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
  signedInUser: User | undefined;
  userAgent: UserAgent;
  serviceMode: ServiceMode;
}
type Interceptor = (context: InterceptorContext) => InterceptorContext;

const fetchUserSession: Interceptor = (context: InterceptorContext) => {
  // TODO 認可も考慮する
  return { ...context, signedInUser: getSignedInUser() };
};

const fetchUserAgent: Interceptor = (context: InterceptorContext) => {
  const userAgent = getUserAgent();
  return { ...context, userAgent };
};

const fetchMaintenanceMode: Interceptor = (context: InterceptorContext) => {
  // TODO メンテナンスモード値をAPI経由で参照
  return { ...context, serviceMode: serviceMode.Normal };
};

function applyInterceptors(
  context: InterceptorContext,
  interceptors: Interceptor[],
): InterceptorContext {
  /* interceptorを順番に適用してcontextを更新してから返す */

  let currentContext: InterceptorContext = context;
  interceptors.forEach((interceptor) => {
    currentContext = interceptor(currentContext);
  });

  return currentContext;
}

// 認証しているユーザだけが入れる
export const requireSignGuard: NavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const initialContext: InterceptorContext = {
    to,
    from,
    signedInUser: undefined,
    userAgent: {
      deviceType: undefined,
      osName: undefined,
      browserName: undefined,
      browserFullVersion: undefined,
    },
    serviceMode: serviceMode.Maintenance,
  };
  const resultContext = applyInterceptors(initialContext, [
    fetchUserAgent,
    fetchMaintenanceMode,
    fetchUserSession,
  ]);

  if (
    Object.values(unavailableDevice).find(
      (v) => v === resultContext.userAgent.deviceType,
    ) != undefined
    // ||
    // iOSはデバイス取得できないのでOS指定で判定 (実装はuserAgent側)
    // resultContext.userAgent.osName === unavailableOs.iOS
  ) {
    // Block List登録済みのデバイスだった場合は、非対応デバイス通知ページへ強制遷移する
    next({
      name: 'ProductUnavailable',
      query: { reason: unavailableReason.Device },
    });
  } else if (
    Object.values(availableBrowser).find(
      (v) => v === resultContext.userAgent.browserName,
    ) == undefined
  ) {
    // Accept List登録済みのブラウザ以外だった場合は、非対応ブラウザ通知ページへ強制遷移する
    next({
      name: 'ProductUnavailable',
      query: { reason: unavailableReason.Browser },
    });
  } else if (resultContext.serviceMode === serviceMode.Maintenance) {
    // メンテナンスモードだった場合はメンテナンスページへ強制遷移する
    next({
      name: 'ProductUnavailable',
      query: { reason: unavailableReason.Maintenance },
    });
  } else if (
    resultContext.signedInUser == undefined ||
    resultContext.signedInUser.isSignedIn === false
  ) {
    // 認証していない場合は強制遷移する

    // トークルームへ行こうとした場合はトップページへ
    // サインイン後にトークルームへ戻す導線を避け、エントランスを経由させたい
    if (to.name === 'DialogueRoom') {
      next({
        name: 'ProductOverview',
      });
    }
    // その他ページへ行こうとした場合はサインインページへ
    else {
      next({
        name: 'SignIn',
      });
    }
  } else if (
    to.name === 'AggregationDb' &&
    resultContext.signedInUser.role?.permissions.find(
      (permission) =>
        permission === userPermission.Execute_Select_From_Aggregation_Db,
    ) == undefined
  ) {
    // 集計用DBページへ行こうとしたのに権限が無い場合は強制遷移する
    next({
      name: 'ProductOverview',
    });
  } else next();
};

// 認証していないユーザも入れる
export const withoutSignGuard: NavigationGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const initialContext: InterceptorContext = {
    to,
    from,
    signedInUser: undefined,
    userAgent: {
      deviceType: undefined,
      osName: undefined,
      browserName: undefined,
      browserFullVersion: undefined,
    },
    serviceMode: serviceMode.Maintenance,
  };
  const resultContext = applyInterceptors(initialContext, [
    fetchUserAgent,
    fetchMaintenanceMode,
  ]);

  if (
    Object.values(unavailableDevice).find(
      (v) => v === resultContext.userAgent.deviceType,
    ) != undefined
    // ||
    // iOSはデバイス取得できないのでOS指定で判定 (実装はuserAgent側)
    // resultContext.userAgent.osName === unavailableOs.iOS
  ) {
    // Block List登録済みのデバイスだった場合は、非対応デバイス通知ページへ強制遷移する
    next({
      name: 'ProductUnavailable',
      query: { reason: unavailableReason.Device },
    });
  } else if (
    Object.values(availableBrowser).find(
      (v) => v === resultContext.userAgent.browserName,
    ) == undefined
  ) {
    // Accept List登録済みのブラウザ以外だった場合は、非対応ブラウザ通知ページへ強制遷移する
    next({
      name: 'ProductUnavailable',
      query: { reason: unavailableReason.Browser },
    });
  } else if (resultContext.serviceMode === serviceMode.Maintenance) {
    // メンテナンスモードだった場合はメンテナンスページへ強制遷移する
    next({
      name: 'ProductUnavailable',
      query: { reason: unavailableReason.Maintenance },
    });
  } else next();
};
