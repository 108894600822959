import { Target } from '@/util/enum';

export function getTarget(): Target {
  const target = process.env.VITE_APP_TARGET;
  if (target == undefined) throw new Error('ターゲットが指定されていません');

  if (target === Target.PROD) return Target.PROD;
  else if (target === Target.STG) return Target.STG;
  else if (target === Target.LOCAL) return Target.LOCAL;
  else if (target === Target.TEST) return Target.TEST;
  else throw new Error(`不明なターゲットです: ${target}`);
}

export function getChimeMock(): boolean {
  const chimeMock = process.env.VITE_APP_CHIME_MOCK;
  // 明示的にfalse指定した時以外はモックする
  const isMocked = !(chimeMock?.toLowerCase() === 'false');
  return isMocked;
}

export function getAudioWorkletMock(): boolean {
  const audioWorkletMock = process.env.VITE_APP_AUDIO_WORKLET_MOCK;
  // 明示的にfalse指定した時以外はモックする
  const isMocked = !(audioWorkletMock?.toLowerCase() === 'false');
  return isMocked;
}

export function getFaceExpressionMock(): boolean {
  const faceExpressionMock = process.env.VITE_APP_FACE_EXPRESSION_MOCK;
  // 明示的にfalse指定した時以外はモックする
  const isMocked = !(faceExpressionMock?.toLowerCase() === 'false');
  return isMocked;
}

export function getGoogleOAuthMock(): boolean {
  const oauthMock = process.env.VITE_APP_GOOGLE_OAUTH_MOCK;
  // 明示的にfalse指定した時以外はモックする
  const isMocked = !(oauthMock?.toLowerCase() === 'false');
  return isMocked;
}

export function getGoogleOAuth2ClientId(target: Target): string {
  const clientId =
    target === Target.PROD
      ? process.env.GOOGLE_OAUTH2_CLIENT_ID_PROD
      : target === Target.STG
      ? process.env.GOOGLE_OAUTH2_CLIENT_ID_STG
      : // Local開発時は、環境変数があれば使う
      target === Target.LOCAL
      ? process.env.GOOGLE_OAUTH2_CLIENT_ID_LOCAL ||
        'local-google-oauth2-client-id'
      : 'testing-google-oauth2-client-id';

  if (clientId == undefined)
    throw new Error('Google OAuth クライアントIDがありません');
  else return clientId;
}

export function getOfficialDocumentBaseUrl(target: Target): string {
  const url =
    target === Target.LOCAL
      ? process.env.VITE_APP_OFFICIAL_DOCUMENT_BASE_URL ||
        'http://localhost:3300'
      : target === Target.TEST
      ? 'http://localhost:3300'
      : process.env.VITE_APP_OFFICIAL_DOCUMENT_BASE_URL;

  if (url == undefined) throw new Error('公式文書アクセス用URLがありません');
  else return url;
}
