import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { requireSignGuard, withoutSignGuard } from './navigationGuard';

const routes: RouteRecordRaw[] = [
  /* (部分的に or 完全に) サインインせずに使える機能 ==========================
   */

  {
    path: '/',
    name: 'ProductOverview',
    component: () =>
      Promise.resolve(import('@/views/ProductOverview/ProductOverview.vue')),
    beforeEnter: withoutSignGuard,
  },
  {
    path: '/unavailable',
    name: 'ProductUnavailable',
    component: () =>
      Promise.resolve(
        import('@/views/ProductUnavailable/ProductUnavailable.vue'),
      ),
  },
  {
    path: '/terms',
    name: 'ProductTerms',
    component: () =>
      Promise.resolve(import('@/views/ProductTerms/ProductTerms.vue')),
    beforeEnter: withoutSignGuard,
  },
  {
    path: '/privacy-policy',
    name: 'ProductPrivacyPolicy',
    component: () =>
      Promise.resolve(
        import('@/views/ProductPrivacyPolicy/ProductPrivacyPolicy.vue'),
      ),
    beforeEnter: withoutSignGuard,
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => Promise.resolve(import('@/views/AboutUs/AboutUs.vue')),
    beforeEnter: withoutSignGuard,
  },
  {
    path: '/dialogues',
    name: 'DialogueList',
    component: () =>
      Promise.resolve(import('@/views/DialogueList/DialogueList.vue')),
    beforeEnter: withoutSignGuard,
  },
  {
    path: '/dialogues/:dialogueID',
    name: 'DialogueEntrance',
    component: () =>
      Promise.resolve(import('@/views/DialogueEntrance/DialogueEntrance.vue')),
    beforeEnter: withoutSignGuard,
  },
  {
    path: '/toi-box',
    name: 'DialogueToiBox',
    component: () =>
      Promise.resolve(import('@/views/DialogueToiBox/DialogueToiBox.vue')),
    beforeEnter: withoutSignGuard,
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => Promise.resolve(import('@/views/SignIn/SignIn.vue')),
    beforeEnter: withoutSignGuard,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => Promise.resolve(import('@/views/SignUp/SignUp.vue')),
    beforeEnter: withoutSignGuard,
  },

  /* サインイン必須の機能 ==========================
   */

  {
    path: '/own-dialogues',
    name: 'DialogueOwnList',
    component: () =>
      Promise.resolve(import('@/views/DialogueOwnList/DialogueOwnList.vue')),
    beforeEnter: requireSignGuard,
  },
  {
    path: '/create-dialogue',
    name: 'DialogueCreate',
    component: () =>
      Promise.resolve(import('@/views/DialogueCreate/DialogueCreate.vue')),
    beforeEnter: requireSignGuard,
  },
  {
    path: '/dialogues/:dialogueID/room',
    name: 'DialogueRoom',
    component: () =>
      Promise.resolve(import('@/views/DialogueRoom/DialogueRoom.vue')),
    beforeEnter: requireSignGuard,
  },
  {
    path: '/own-notes',
    name: 'NoteOwnList',
    component: () =>
      Promise.resolve(import('@/views/NoteOwnList/NoteOwnList.vue')),
    beforeEnter: requireSignGuard,
  },
  {
    path: '/dialogues/:dialogueID/notes/:noteID',
    name: 'NoteLounge',
    component: () =>
      Promise.resolve(import('@/views/NoteLounge/NoteLounge.vue')),
    beforeEnter: requireSignGuard,
  },
  {
    path: '/dialogues/:dialogueID/edit-note',
    name: 'NoteEdit',
    component: () => Promise.resolve(import('@/views/NoteEdit/NoteEdit.vue')),
    beforeEnter: requireSignGuard,
  },
  // スタッフ用
  {
    path: '/staff',
    redirect: '/staff/aggregation-db',
  },
  {
    path: '/staff/aggregation-db',
    name: 'AggregationDb',
    component: () =>
      Promise.resolve(import('@/views/AggregationDb/AggregationDb.vue')),
    beforeEnter: requireSignGuard,
  },
  {
    path: '/staff/room-management',
    name: 'RoomManagement',
    component: () =>
      Promise.resolve(import('@/views/RoomManagement/RoomManagement.vue')),
    beforeEnter: requireSignGuard,
  },
  // その他のあらゆるページ
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior() {
    // 画面遷移後はページ最上部へ移動させる
    return { top: 0 };
  },
});

export default router;
