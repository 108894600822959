import { UAParser } from 'ua-parser-js';
// import { unavailableOs } from '@/util/enum';

/* iOSを判定するための一時的な措置
 * cf. https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
 * TODO ua-parser-jsが対応したら差し替える
 * cf. https://github.com/faisalman/ua-parser-js/issues/387
 */
function isiOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}
export function isMobile(): boolean {
  if (isiOS()) {
    return true;
  }

  let isPhone = false;
  ['Windows Phone', 'Android'].forEach((text) => {
    if (navigator.userAgent.includes(text)) {
      isPhone = true;
    }
  });

  return isPhone;
}

export interface UserAgent {
  deviceType: string | undefined;
  osName: string | undefined;
  browserName: string | undefined;
  browserFullVersion: string | undefined;
}

export function getUserAgent(): UserAgent {
  const ua = new UAParser();

  return {
    deviceType: ua.getDevice().type,
    osName: isiOS()
      ? // ? unavailableOs.iOS
        'iOS'
      : ua.getOS().name,
    browserName: ua.getBrowser().name,
    browserFullVersion: ua.getBrowser().version,
  };
}
