import { createApp, h, defineAsyncComponent } from 'vue';
import 'vuetify/styles';
import 'vuetify/dist/vuetify.min.css';
import vuetify from '@/plugins/vuetify';
import router from '@/router/index';
import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/vue';
import { Target } from '@/util/enum';
import { product } from '@/util/const';

/* `global is not defined`エラーを避ける (polyfill)
 * cf. https://github.com/bevacqua/dragula/issues/602#issuecomment-477734315
 */
if (typeof (window as any).global === 'undefined') {
  (window as any).global = window;
}

const app = createApp({
  render: () =>
    h(defineAsyncComponent(() => import('@/ProductApplication.vue'))),
})
  .use(vuetify)
  .use(router);

if (product.target === Target.PROD || product.target === Target.STG) {
  Sentry.init({
    app,
    dsn: 'https://59c0cdb125753df99ea579d9fa15e80e@o4506784280346624.ingest.sentry.io/4506784295747584',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      captureConsoleIntegration({ levels: ['error'] }),
    ],
    attachStacktrace: true,

    // 1.0 = 100%
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/shabelo\.com\/api/,
      /^https:\/\/d2gdiau7lkugeu4ephlaj8tf9sobrpm\.net\/api/,
    ],
  });
}

app.mount('#app');
